export const COLORS = {
  text: {
    light: 'hsl(140deg, 12%, 29%)', // Dark sage
    dark: 'hsl(36, 29%, 97%)', // nice warm white
  },
  background: {
    light: 'hsl(36, 29%, 97%)', // nice warm white
    dark: 'hsl(140deg, 16%, 18%)', // Dark sage
  },
  primary: {
    light: 'hsl(340deg, 100%, 40%)', // Pinkish-red
    dark: 'hsl(50deg, 100%, 50%)', // Yellow
  },
  secondary: { // links
    light: 'hsl(2deg, 27%, 58%)', // Coral
    dark: 'hsl(141deg, 42%, 86%)', // Very light sage 
  },
  visited: {
    light: 'hsl(2deg, 57%, 74%)',
    dark: 'hsl(142deg, 84%, 93%)',
  },
  success: {
    light: 'hsl(140deg, 16%, 18%)', // Dark sage
    dark: 'hsl(141deg, 42%, 86%)', // Very light sage 
  },
  error: {
    light: 'hsl(0, 50.9%, 57.6%)', // Soft red
    dark: 'hsl(0, 100%, 81.4%)', // Very light orange
  },
  // Grays, scaling from least-noticeable to most-noticeable
  gray300: {
    light: 'hsl(0deg, 0%, 70%)',
    dark: 'hsl(0deg, 0%, 30%)',
  },
  gray500: {
    light: 'hsl(0deg, 0%, 50%)',
    dark: 'hsl(0deg, 0%, 50%)',
  },
  gray700: {
    light: 'hsl(0deg, 0%, 30%)',
    dark: 'hsl(0deg, 0%, 70%)',
  },
  grey300: {
    light: 'hsl(0deg, 0%, 70%)',
    dark: 'hsl(0deg, 0%, 30%)',
  },
  grey500: {
    light: 'hsl(0deg, 0%, 50%)',
    dark: 'hsl(0deg, 0%, 50%)',
  },
  grey700: {
    light: 'hsl(0deg, 0%, 30%)',
    dark: 'hsl(0deg, 0%, 70%)',
  },
};

export const COLOR_MODE_KEY = 'color-mode';
export const INITIAL_COLOR_MODE_CSS_PROP = '--initial-color-mode';